.reactionsPanel {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 36px;
  height: 207px;
  bottom: 55px;
  right: 18px;
}

@media (min-height: 710px) {
  .reactionsPanel {
    bottom: 115px;
  }
}

.reactionsPanel__countable {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reactionsPanel__nonCountable {
  cursor: pointer;
  height: 37px;
}

.reactionsPanel_number {
  color: #fff;
  font-family: "Poppins", "sans-serif";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 8px;
  letter-spacing: 1px;
  text-shadow: -0.5px -0.5px 0 #121212, 0.5px -0.5px 0 #121212,
    -0.5px 0.5px 0 #121212, 0.5px 0.5px 0 #121212;
}

.material-icons {
  font-size: 32px;
  transition: color 0.3s ease;
}

.liked {
  color: red;
  fill: red;
}

.icon.like {
  transition: color 0.3s;
}

.icon:hover {
  cursor: pointer;
}
