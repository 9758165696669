*,
body {
  margin: 0;
  padding: 0;
}

.feed {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.feed__centeredMessage {
  text-align: center;
}

@media (max-width: 480px) {
  .feed {
    height: calc(100% - 52px);
    /* 52px top header feed */
    width: 100%;
  }

  .feed::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 481px) {
  .feed {
    height: 100%;
    width: 100%;
  }
}

.content_container,
.singleStoryContentContainer {
  flex-shrink: 0;
  position: relative;
  scroll-snap-align: start;
  outline: none;
  border-radius: 20px;
}

.content_container::-webkit-scrollbar,
.singleStoryContentContainer::-webkit-scrollbar {
  display: none;
}

@media (max-width: 480px) {
  .content_container,
  .singleStoryContentContainer {
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
  }
  .content_container {
    margin-bottom: 20px;
  }
}

@media (min-width: 481px) {
  .content_container:first-child {
    margin-top: 40px;
    /* only for feed */
  }

  .content_container,
  .singleStoryContentContainer {
    aspect-ratio: 1 / 1.5;
    height: 85%;
    max-width: 500px;
    max-height: 750px;
    scroll-snap-align: center;
    box-shadow: 0px 11px 26px -8px rgba(191, 185, 185, 1);
    border: 1px solid #e5e7eb;
  }
  .content_container {
    margin-bottom: 40px;
  }
}

@media (min-width: 481px) and (max-height: 600px) {
  .content_container,
  .singleStoryContentContainer {
    height: 94%;
  }
}

.react-swipeable-view-container {
  height: 100%;
}

.lds-ellipsis div {
  background-color: #507f6e !important;
}

.feed__inviewMarkAsRead {
  position: absolute;
  bottom: -200px;
  width: 100%;
  height: 200px;
  background-color: transparent;
}

@media (min-width: 481px) {
  .singleStoryContainer {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
