.background {
  position: relative;
  width: 100%;
  height: 100%;
}

@media (min-width: 481px) {
  .background {
    display: flex;
    justify-content: center;
  }
}

.arrowBack {
  position: absolute;
  top: 20px;
  left: 20px;
}

.groupNameContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .groupNameContainer {
    padding: 40px;
  }
}

@media (min-width: 481px) {
  .groupNameContainer {
    top: 20%;
    box-shadow: 8px 8px 24px 0px rgba(217, 217, 217, 1);
    border-radius: 10px;
    padding: 0px 80px;
    height: 300px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media (max-width: 480px) {
  .content {
    padding-top: 100px;
  }
}

@media (min-width: 481px) {
  .content {
    padding-top: 50px;
  }
}

.input {
  border: none;
  outline: none;
  width: 100%;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
  font-size: 16px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
