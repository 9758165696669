.button {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1.2px;
  background-color: transparent;

  svg {
    width: 100%;
  }
}
.button:hover {
  scale: 1.05;
  transition: 0.2s;
}
