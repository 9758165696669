.app {
  display: flex;
  height: 100vh;
}

.content {
  flex-grow: 1;
  height: 100%;
  overflow: auto;
}

.appContentMobile {
  position: fixed;
  top: 0px;
  bottom: 55px;
  left: 0px;
  right: 0px;
  width: 100%;
  overflow: auto;
}

.appContentTablet {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 60px;
  right: 0px;
  width: calc(100% - 60px);
  overflow: auto;
}

.appContentLargeScreen {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 220px;
  right: 0px;
  width: calc(100% - 220px);
  overflow: auto;
}
