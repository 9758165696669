.discussion__div {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.discussion__commentsContainer {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  flex-grow: 1;
}

.discussion__searchesContainer {
  position: relative;
  overflow-y: auto;
  width: 100%;
  flex-grow: 1;
}

.discussion__typeCommentContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  bottom: 0px;
  width: 100%;
  height: auto;
  padding: 8px 22px;
  border-top: 1px solid #d9d9d9;
}
