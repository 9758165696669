.background {
  width: 100%;
  height: 100%;
}

@media (min-width: 481px) {
  .background {
    display: flex;
    justify-content: center;
  }
}

.overview {
  position: relative;
  overflow-y: auto;
}

@media (max-width: 480px) {
  .overview {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 481px) {
  .overview {
    width: 50%;
    min-width: 450px;
    max-width: 600px;
    margin-top: 20px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 8px 8px 24px 0px rgba(217, 217, 217, 1);
  }
}

.header {
  position: relative;
}

.addNewUser {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding-left: 30px;
  padding-right: 20px;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid var(--gray);

  span {
    margin-left: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: -0.408px;
    color: var(--dark-green);
  }
}

@media (min-width: 481px) {
  .addNewUser:hover {
    background-color: var(--gray-on-hover);
    transition: 0.3s;
  }
}

.membersList {
  position: relative;
  overflow-y: auto;
}
