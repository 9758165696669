.textareaDiv {
  position: relative;
  display: flex;
  align-items: end;
  min-height: 32px;
  max-height: 300px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #0f5940;
  border-radius: 20px;
  letter-spacing: -0.408px;
  overflow-y: scroll;
}

.textareaDiv > div {
  position: relative;
  display: flex;
  align-items: end;
  height: 100%;
  right: 5px;
  cursor: pointer;
}

.comment__input {
  flex-grow: 1;
  max-height: 300px;
  font-size: 16px;
  line-height: 22px;
  resize: none;
  padding: 5px 5px 5px 10px;
  border: none;
}

.comment__input::-webkit-scrollbar,
.textareaDiv::-webkit-scrollbar {
  display: none;
}

.comment__input::placeholder {
  color: #8d8d92;
  font-size: 16px;
  line-height: 22px;
}

.comment__input:focus {
  outline: none;
}

.textArea__CircularProgressDiv {
  padding-bottom: 1px;
}
