.div {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #d9d9d9;
}

@media (max-width: 480px) {
  .div {
    padding: 6px 20px;
  }
}

@media (min-width: 481px) {
  .div {
    padding: 8px 20px;
  }
  .div:hover {
    background-color: var(--gray-on-hover);
    transition: 0.3s;
  }
}

.groupName {
  margin-left: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
}
