@import "../../components/global.module.css";
.container {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--gray);
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar {
  margin-bottom: 10px;
}

.groupName {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
}
.membersCount {
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #939191;
}
.icons {
  width: 25px;
  cursor: pointer;
}

@media (min-width: 481px) {
  .icons:hover {
    size: 1.2;
    transition: 0.2s;
  }
}
