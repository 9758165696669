:root {
  --green-main: #507f6e;
  --gray: #d9d9d9;
  --dark-green: #0f5940;
  --light-green: #4be148;
  --red-error: #ff0033;
  --green-avatar: #5bba97;
  --blue-avatar: #3d7698;
  --red-font: #d80e33;
  --gray-on-hover: #f4f4f4;
}

.messageInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 200px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.bottomLoader {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.centralMessage {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  text-align: center;
}

.avatarNotificationDot {
  position: absolute;
  height: 10px;
  width: 10px;
  top: -1px;
  right: 10px;
  border-radius: 50%;
  background-color: #0f7d57;
}

.removeLinkDecoration {
  text-decoration: none;
  color: black;
}
