.background {
  position: absolute;
  background-color: white;
  z-index: 2;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

@media (min-width: 481px) {
  .background {
    display: flex;
    justify-content: center;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (max-width: 481px) {
  .container {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 481px) {
  .container {
    width: 50%;
    min-width: 450px;
    max-width: 600px;
    margin-top: 20px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 8px 8px 24px 0px rgba(217, 217, 217, 1);
    overflow: hidden;
  }
}

.header {
  position: relative;
  top: 0px;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.arrowBack {
  margin-right: 20px;
  cursor: pointer;
}

.contactsList {
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
}

.footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  flex-shrink: 0;
  background-color: #fff;

  button {
    width: 155px;
    height: 35px;
  }
}
