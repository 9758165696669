.background {
  width: 100%;
}

@media (min-width: 481px) {
  .background {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .mainContainer {
    width: 100%;
    border-top: 1px solid #d9d9d9;
  }
}

@media (min-width: 481px) {
  .mainContainer {
    width: 50%;
    min-width: 450px;
    max-width: 600px;
    margin-top: 20px;
    margin-bottom: 50px;
    border-radius: 10px;
    box-shadow: 8px 8px 24px 0px rgba(217, 217, 217, 1);
    overflow: hidden;
  }
}

.navContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
}

.nav {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
}

.buttonDiv {
  padding-left: 10px;
  padding-right: 10px;
}

.borders {
  border-left: 1px solid var(--dark-green);
  border-right: 1px solid var(--dark-green);
}

.outlet {
  width: 100%;
  padding: 10px 0;
}
