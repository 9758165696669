.contactsListContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.information,
.loading {
  width: 100%;
  height: 120px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loadingBottom {
  padding-top: 10px;
  height: 50px;
}

.bottomInView {
  height: 1px;
  width: 100%;
}
