.background {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.container {
  position: relative;
  display: flex;
  background-color: white;
  aspect-ratio: 1.5 / 1;
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 1250px) {
  .container {
    width: 92%;
    max-height: 92%;
  }
}

@media (min-width: 1250px) {
  .container {
    height: 92%;
    max-height: 800px;
  }
}

.newsContainer {
  position: relative;
  height: 100%;
  max-width: 50%;
  aspect-ratio: 1 / 1.4;
}
.discussionsContainer {
  position: relative;
  height: 100%;
  flex-grow: 1;
  border-left: 1px solid #d9d9d9;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 18px;
  border: none;
  background-color: transparent;
  color: white;
}
