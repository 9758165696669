.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  overflow-y: auto;
}

.arrowBack {
  position: absolute;
  top: 13px;
  left: 18px;
}

.card {
  width: calc(100% - 40px);
  padding: 30px 20px;
  box-shadow: 0px 11px 26px -8px rgba(191, 185, 185, 1);
  border: 1px solid #e5e7eb;
  background-color: white;
  border-radius: 20px;
  margin-bottom: 30px;

  h1 {
    font-weight: 700;
    text-align: center;
    color: var(--dark-green);
    font-size: 22px;
  }

  h2 {
    font-weight: 600;
    text-align: center;
    color: var(--dark-green);
    font-size: 20px;
  }
}

.cardContent {
  color: rgb(55 65 81);
  padding-top: 15px;
}

.listItem {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 16px;

  p {
    line-height: 100%;
    margin-bottom: 0px;
    margin-left: 8px;
  }

  .icon {
    color: var(--dark-green);
    flex-shrink: 0;
  }
}

.codeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(243 244 246);
  border-radius: 10px;
  padding: 10px;

  button {
    padding: 6px 12px;
    background-color: var(--dark-green);
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 20px;
  }
}

.copied {
  text-align: center;
  margin-top: 8px;
  font-weight: 500;
  color: var(--green-main);
}
